export default {
    getPatientHealthInfo(state) {
        const data = state.patientHealthData[0];
        const ObjDisease = {1:'ไม่มี',2:'โรคความดันโลหิต',3:'โรคไขมันในเลือด',4:'โรคเบาหวาน',5:'โรคอื่นๆ'};
        const ObjDrug = {1:"กลุ่มยาลดความดันโลหิต",2:"กลุ่มยาลดไขมันในเลือด",3:"กลุ่มยารักษาโรคเบาหวาน",4:"กลุ่มยารักษาโรคหัวใจ",100:"ไม่มี",5:"อื่นๆ"}
        const ObjDisabilities = {100:'ไม่มี',1:'ความพิการทางการเห็น',2:'ความพิการทางการได้ยิน',3:'ความพิการทางการเคลื่อนไหว/ทางร่างกาย',4:'ความพิการทางจิตใจ/พฤติกรรม',5:'ความพิการทางสติปัญญา',6:'ความพิการทางการเรียนรู้',7:'ความพิการทางการออทิสติก'};
        const congenital_disease = mapInfo(data.congenital_disease , ObjDisease, data.other_congenital_disease);
        const drug_currently = mapInfo(data.drug_currently , ObjDrug, data.other_drug_currently);
        const disabilities_registration = mapInfo(data.disabilities_registration , ObjDisabilities,"");
        return {
            fullName : data.firstname + ' ' + data.lastname,
            congenital_disease : congenital_disease,
            drug_allergy : data.drug_allergy === 2 ? 'ไม่มี' : data.other_drug_allergy ?? 'ไม่พบข้อมูล',
            drug_currently : drug_currently,
            health_problems : data.health_problems ?? 'ไม่พบข้อมูล',
            disabilities_registration : disabilities_registration,
            weight : data.weight ?? '-',
            height : data.height ?? '-',
            waistline : data.waistline ?? '-',
            bmi : data.bmi ?? 'ไม่พบข้อมูล',
            body_temperature : data.body_temperature ?? '-',
            pulse : data.pulse ?? '-',
            bp_sys : data.bp_sys ?? '-',
            bp_dia : data.bp_dia ?? '-',
            respiration_rate : data.respiration_rate ?? '-',
            blood_oxygen : data.blood_oxygen ?? '-',
            blood_glucose : data.blood_glucose ?? '-',
            ltc_status : data.ltc_status ?? 'ไม่พบข้อมูล',
        };
    },
};


function mapInfo(infoString, infoObject, otherInfo) {
    if(infoString === null) return ["ไม่พบข้อมูล"];
    const infoArray = infoString.split(',');
    let result = [];
        for (let item of infoArray) {
        if (infoObject[item] === 'โรคอื่นๆ' || infoObject[item] === 'อื่นๆ') {
            result.push(otherInfo);
        } else {
            result.push(infoObject[item] ?? 'ไม่พบข้อมูล');
        }
    }
        return result;
}