import Vue from "vue";
import Vuex from "vuex";
import registerStore from "./register";
import staffListPatientStore from "./staff/list-patient/index";
import staffDetailsPatientStore from "./staff/detail-patient/index";
import searchStore from "./search/index";
import adminDashboardStore from "./admin/dashboard/index";
import adminDashboardMenuStore from "./admin/dashboardMenu/index";
import adminCareGiverStore from "./admin/careGiver/index";
import adminPatientInfoStore from "./admin/patientInfo/index";
import adminPatientHealthStore from "./admin/healthInfo/index";
import trackVisitMenuStore from "./track-visit/menu/index";
import tracVisitReportStore from "./track-visit/report/index"
import estimateSoresStore from "./track-visit/estimate-sores/index";
import pressureSoresStore from "./track-visit/pressure-sores/index"
import evaluateStore from "./track-visit/evaluate-activity/index"
import router from "@/router";


Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        register: registerStore,
        staffListPatient: staffListPatientStore,
        staffDetailsPatientStore: staffDetailsPatientStore,
        search: searchStore,
        adminDashboardStore: adminDashboardStore,
        adminDashboardMenuStore: adminDashboardMenuStore,
        adminCareGiverStore: adminCareGiverStore,
        adminPatientInfo: adminPatientInfoStore,
        adminPatientHealth: adminPatientHealthStore,
        trackVisitMenu: trackVisitMenuStore,
        tracVisitReport: tracVisitReportStore,
        estimateSores: estimateSoresStore,
        pressureSores: pressureSoresStore,
        evaluate: evaluateStore
    },
    state: {
        imageBase64: ""
    },
    mutations: {
        setImageBase64(state, payload) {
            state.imageBase64 = payload;
        },
    },
    getters: {
        getImageBase64(state) {
            return state.imageBase64;
        }
    },
    actions: {
        goBack() {
            router.go(-1);
        },
        handleImages({commit}, files) {
            // convert each file to base64
            const base64 = [];
            for (let i = 0; i < files.length; i++) {
                const reader = new FileReader();
                reader.readAsDataURL(files[i]);
                reader.onload = () => {
                    base64.push(reader.result);
                };
            }
            commit("setImageBase64", base64);
        }
    }
});
