import store from "@/store";
export default {
    name: 'admin',
    path: '/admin',
    redirect: '/admin/dashboard',
    component: {
        render(h) {
            return h('router-view');
        }
    },
    children: [
        {
            path: "dashboard",
            name: "adminDashboard",
            component: () => import('@/views/admin/adminDashboardView.vue'),
            beforeEnter: async (to, from, next) => {
                await store.dispatch('adminDashboardStore/getTableData')
                    .then(() => {
                        next();
                    })
            }
        },
        {
            path: "dashboard-menu",
            name: "adminDashboardMenu",
            component: () => import('@/views/admin/adminDashboardMenuView.vue'),
            beforeEnter: async (to, from, next) => {
                await store.dispatch('adminDashboardMenuStore/getInfoOfPatient', store.state.adminDashboardMenuStore.patient_id);
                next();
            }
        },
        {
            name: "staffInfoEdit",
            path: "menu/staff-info-edit",
            component: () => import('@/views/admin/menu/staffInfoEditView.vue'),
            beforeEnter: async (to, from, next) => {
                await store.dispatch('adminCareGiverStore/getCareGiverInfo')
                    .then(() => {
                        next();
                    })
                    .catch(() => {
                        next({name: 'adminDashboard'});
                    })
            }
        },
        {
            name: "personalInfoEdit",
            path: "menu/personal-info-edit",
            component: () => import('@/views/admin/menu/personalInfoEditView.vue'),
            beforeEnter: async (to, from, next) => {
                await store.dispatch('adminPatientInfo/getPatientInfo')
                    .then(() => {
                        next()
                    })
                    .catch(() => {
                        next({name : 'adminDashboard'})
                    })
            }
        },
        {
            name: "healthForm",
            path: "menu/health-form",
            component: () => import('@/views/admin/menu/healthForm.vue'),
            beforeEnter: async (to, from, next) => {
                await store.dispatch('adminPatientHealth/getPatientHealthInfo')
                    .then(() => {
                        next();
                    })
                    .catch(() => {
                        next({name: 'adminDashboard'});
                    })
            }
        }
    ]
}