export default {
    getCareGiverInfo(state) {
        return {
            prefix: state.careGiverData[0].care_giver_title,
            firstName: state.careGiverData[0].care_giver_firstname,
            lastName: state.careGiverData[0].care_giver_lastname,
            tel: state.careGiverData[0].care_giver_tel,
        };
    }
};
