
export default {
    SET_ASSESSMENT_DATE(state,payload){
        state.assessment_date = payload
    },
    SET_ASSESSMENT_SCORE_1(state,payload){
        state.assessment_score_1 = payload
    },
    SET_ASSESSMENT_SCORE_2(state,payload){
        state.assessment_score_2 = payload
    },
    SET_ASSESSMENT_SCORE_3(state,payload){
        state.assessment_score_3 = payload
    },
};
