import router from "@/router";
import repository from "@/repository/admin/index";

export default {
    fillName({commit}, payload) {
        commit("SET_NAME", payload);
    },
    fillAgeMin({commit}, payload) {
        commit("SET_AGE_MIN", payload);
    },
    fillAgeMax({commit}, payload) {
        commit("SET_AGE_MAX", payload);
    },
    fillDisabilities({commit}, payload) {
        commit("SET_DISABILITIES", payload);
    },
    fillBloodGlucoseMin({commit}, payload) {
        commit("SET_BLOOD_GLUCOSE_MIN", payload);
    },
    fillBloodGlucoseMax({commit}, payload) {
        commit("SET_BLOOD_GLUCOSE_MAX", payload);
    },
    fillBloodPressure({commit}, payload) {
        commit("SET_BLOOD_PRESSURE", payload);
    },
    fillLtcStatus({commit, dispatch}, payload) {
        commit("SET_LTC_STATUS", payload);
        dispatch("mapLTC");
    },
    fillADL({commit, dispatch}, payload) {
        commit("SET_ADL", payload);
        dispatch("mapADL");
    },
    mapADL({state}) {
        if (state.ADL === "") {
            state.ADL_TXT = "สถานะ ADL"
        } else if (state.ADL === "1") {
            state.ADL_TXT = "ติดบ้าน"
        } else if (state.ADL === "2") {
            state.ADL_TXT = "ติดสังคม"
        } else if (state.ADL === "3") {
            state.ADL_TXT = "ติดเตียง"
        } else if (state.ADL === "4") {
            state.ADL_TXT = "ทั้งหมด"
        } else if (state.ADL === "0") {
            state.ADL_TXT = "ไม่มี"
        }
    },
    mapLTC({state}) {
        if (state.ltc_status === "") {
            state.LTC_TXT = "สถานะ LTC"
        } else if (state.ltc_status === "1") {
            state.LTC_TXT = "ยืนยัน"
        } else if (state.ltc_status === "2") {
            state.LTC_TXT = "รอการยืนยัน"
        } else if (state.ltc_status === "3") {
            state.LTC_TXT = "ออกจากระบบ LTC"
        } else if (state.ltc_status === "4") {
            state.LTC_TXT = "ทั้งหมด"
        } else if (state.ltc_status === "5") {
            state.LTC_TXT = "ไม่ทราบ"
        } else if (state.ltc_status === "6") {
            state.LTC_TXT = "อื่นๆ"
        }
    },
    async getTableData({commit, state, dispatch}) {
        const adminArea = localStorage.getItem("adminArea");
        if (state.name === "") {
            const {data} = await repository.getTableData({adminArea});
            commit("SET_TABLE_DATA", data);
        } else {
            dispatch("searchPatient");
        }
    },
    async mapPaginationData({commit, state}, query) {
        const adminArea = localStorage.getItem("adminArea");
        const path4Search = `https://${process.env.VUE_APP_BASE_URL}/api/search-admin-dashboard-patient-list`;
        const body = {
            "name": state.name,
            "age_min": state.age_min,
            "age_max": state.age_max,
            "disabilities": state.disabilities,
            "blood_glucose_min": state.blood_glucose_min,
            "blood_glucose_max": state.blood_glucose_max,
            "blood_pressure": state.blood_pressure,
            "ltc_status": state.ltc_status,
            "ADL": state.ADL,
            "administration_area_id": adminArea
        }
        const firstPageUrl = state.table_data.first_page_url;
        const path = firstPageUrl.replace("page=1", `page=${query}`);
        if (state.table_data.path === path4Search) {
                        const {data} = await repository.searchPatient(body);
            commit("SET_TABLE_DATA", data);
        } else {
            const {data} = await repository.getMapPaginationData({path});
            commit("SET_TABLE_DATA", data);
        }
    },
    async searchPatient({commit, state}) {
        const adminArea = localStorage.getItem("adminArea");
        const body = {
            "name": state.name,
            "age_min": state.age_min,
            "age_max": state.age_max,
            "disabilities": state.disabilities,
            "blood_glucose_min": state.blood_glucose_min,
            "blood_glucose_max": state.blood_glucose_max,
            "blood_pressure": state.blood_pressure,
            "ltc_status": state.ltc_status,
            "ADL": state.ADL,
            "administration_area_id": adminArea
        }
        const {data} = await repository.searchPatient(body);
        commit("SET_TABLE_DATA", data);
    },
    async goToDashMenu({commit}, patient_id) {
        commit("adminDashboardMenuStore/SET_PATIENT_ID", patient_id, {root: true});
        await router.push({name: "adminDashboardMenu"})
    }
};
