
export default {
    SET_ASSESSMENT_DATE(state,payload){
        state.assessment_date = payload
    },
    SET_ASSESSMENT_SCORE_1(state,payload){
        state.assessment_score_1 = payload
    },
    SET_ASSESSMENT_SCORE_2(state,payload){
        state.assessment_score_2 = payload
    },
    SET_ASSESSMENT_SCORE_3(state,payload){
        state.assessment_score_3 = payload
    },
    SET_ASSESSMENT_SCORE_4(state,payload){
        state.assessment_score_4 = payload
    },
    SET_ASSESSMENT_SCORE_5(state,payload){
        state.assessment_score_5 = payload
    },
    SET_ASSESSMENT_SCORE_6(state,payload){
        state.assessment_score_6 = payload
    },
};
