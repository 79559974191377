import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./global.css";
import "regenerator-runtime";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {library} from "@fortawesome/fontawesome-svg-core";
import {
    faAngleRight,
    faCircleInfo,
    faDownload,
    faLocationDot,
    faLock,
    faStethoscope,
    faUser,
    faWheelchair
} from "@fortawesome/free-solid-svg-icons";
import {faClipboard, faFileLines, faPenToSquare,} from "@fortawesome/free-regular-svg-icons";
import * as VueGoogleMaps from "vue2-google-maps";

library.add(faFileLines, faPenToSquare, faClipboard, faAngleRight, faWheelchair, faStethoscope, faCircleInfo, faLocationDot, faDownload, faUser, faLock, faDownload);
Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.use(VueGoogleMaps, {
    load: {
        key: process.env.VUE_APP_GMAP_KEY, libraries: "drawing",
    }, installComponents: true,
});
Vue.config.productionTip = false;
new Vue({router, store, render: (h) => h(App)}).$mount("#app");
