import router from "@/router";
import {fail, success, warning} from "@/utils/alertUtils";
import axios from "axios";
import {liff} from "@line/liff";
import loginFunction from "@/utils/login";

export default {
    fillAuthorityId({commit}, payload) {
        commit("SET_AUTHORITY_ID", payload);
    }, fillAreaCode({commit}, payload) {
        commit("SET_AREA_CODE", payload);
    }, fillDataArea({commit}, payload) {
        commit("SET_DATA_AREA", payload);
    }, fillFirstName({commit}, payload) {
        commit("SET_FIRST_NAME", payload);
    }, fillLastName({commit}, payload) {
        commit("SET_LAST_NAME", payload);
    }, fillTel({commit}, payload) {
        commit("SET_TEL", payload);
    }, fillUsername({commit}, payload) {
        commit("SET_USERNAME", payload);
    }, fillPassword({commit}, payload) {
        commit("SET_PASSWORD", payload);
    }, async fetchAreaCode({dispatch, state}) {
        if (state.areaCode === "") {
            await warning("กรุณากรอกรหัสพื้นที่")
        } else {
            await axios.get(`https://api.cmonehealth.org/users/register/group/code/?invitationCode=${state.areaCode}`)
                .then((resp) => {
                    dispatch("fillDataArea", resp.data.name);
                    dispatch("fillAuthorityId", resp.data.id);
                    router.push("/register/area-cg-confirm");
                })
                .catch(() => {
                    warning("รหัสพื้นที่ไม่ถูกต้อง");
                });
        }
    }, //This action use in area-cg-confirm to back to area-cg
    async editArea({commit}) {
        commit("RESET_DATA_AREA", null);
        commit("RESET_AREA_CODE");
        await router.push("/register/area-cg");
        // await router.go();
    },

    async confirmUserForm({state}) {
        const body = {
            "code": state.areaCode,
            "firstName": state.firstName,
            "lastName": state.lastName,
            "telephone": state.tel,
        }
        axios.post(`https://api.cmonehealth.org/users/register/authority/code`, body)
            .then(({data: response}) => {
                success('ลงทะเบียนสำเร็จ')

                liff.sendMessages([{
                    type: 'text',
                    text: `ลงทะเบียนสำเร็จ\nรหัสผู้ใช้คือ: ${response.username}\nรหัสผ่านคือ: ${response.displayPassword}\nสังกัด ${response.authority.name}`
                }])

                liff.getProfile().then((profile) => {
                    this.username = response.username;
                    this.password = response.displayPassword;
                    loginFunction(profile.userId, this.username, this.password);
                })
            })
            .catch(() => {
                fail('ไม่สามารถลงทะเบียนได้')
            });
    },

    async goToUserForm() {
        await router.push("/register/user-form");
    },
};
