import router from "@/router";
import repository from "@/repository/track-visit/index";
import {trackVisitPostData} from "@/utils/alertUtils";

export default {
    fillScore1({commit}, score) {
        commit('SET_ASSESSMENT_SCORE_1', score)
    }, fillScore2({commit}, score) {
        commit('SET_ASSESSMENT_SCORE_2', score)
    }, fillScore3({commit}, score) {
        commit('SET_ASSESSMENT_SCORE_3', score)
    }, fillScore4({commit}, score) {
        commit('SET_ASSESSMENT_SCORE_4', score)
    }, fillScore5({commit}, score) {
        commit('SET_ASSESSMENT_SCORE_5', score)
    }, fillScore6({commit}, score) {
        commit('SET_ASSESSMENT_SCORE_6', score)
    }, async postScore({getters, rootState, state}) {
        const created_by = JSON.parse(localStorage.getItem('reqData')).name
        const data = {
            "patient_id": rootState.trackVisitMenu.patient_id,
            "assessment_date": getters.getCurrentDate,
            "assessment_score_1": state.assessment_score_1,
            "assessment_score_2": state.assessment_score_2,
            "assessment_score_3": state.assessment_score_3,
            "assessment_score_4": state.assessment_score_4,
            "assessment_score_5": state.assessment_score_5,
            "assessment_score_6": state.assessment_score_6,
            "created_by": created_by,
        }
        await trackVisitPostData(repository.postPressureUlcerRiskAssessment, data, router, rootState)
    }
}
