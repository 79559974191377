import router from "@/router";
import repository from "@/repository/track-visit/index";
import {trackVisitPostData} from "@/utils/alertUtils";

export default {
    fillNo1({commit}, no1) {
        commit('SET_NO1', no1)
    },
    fillNo2({commit}, no2) {
        commit('SET_NO2', no2)
    },
    fillNo3({commit}, no3) {
        commit('SET_NO3', no3)
    },
    fillNo4({commit}, no4) {
        commit('SET_NO4', no4)
    },
    fillNo5({commit}, no5) {
        commit('SET_NO5', no5)
    },
    fillNo6({commit}, no6) {
        commit('SET_NO6', no6)
    },
    fillNo7({commit}, no7) {
        commit('SET_NO7', no7)
    },
    fillNo8({commit}, no8) {
        commit('SET_NO8', no8)
    },
    fillNo9({commit}, no9) {
        commit('SET_NO9', no9)
    },
    fillNo10({commit}, no10) {
        commit('SET_NO10', no10)
    },
    fillBeforeReportData({commit}, beforeReportData) {
        commit('SET_BEFORE_REPORT_DATA', beforeReportData)
    },
    async postScore({getters, rootState, state}) {
        const created_by = JSON.parse(localStorage.getItem('reqData')).name
        const data = {
            "total_score": getters.getTotalScore,
            "patient_id": rootState.trackVisitMenu.patient_id,
            "no1": state.no1,
            "no2": state.no2,
            "no3": state.no3,
            "no4": state.no4,
            "no5": state.no5,
            "no6": state.no6,
            "no7": state.no7,
            "no8": state.no8,
            "no9": state.no9,
            "no10": state.no10,
            "created_by": created_by,
        }
        await trackVisitPostData(repository.postExamResultRegister, data, router, rootState)
    }
}
