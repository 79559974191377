import repository from "@/repository";

export default {
    async getCareGiverInfo(payload){
        return repository.get(`/get-caregiver-info/${payload.patient_id}`)
    },
    async getPatientHealthInfo(payload){
        return repository.get(`/get-health-info/${payload.patient_id}`)
    },
    async getPatientInfo(payload){
        return repository.get(`/get-patient-info/${payload.patient_id}`)
    },
    async getInfoOfPatient(payload){
        return repository.get(`/patient-qr-register/${payload.patient_id}`)
    },
    async getTableData(payload){
        return repository.get(`/get-admin-dashboard-patient-list/${payload.adminArea}`)
    },
    async searchPatient(payload){
        return repository.get(`/search-admin-dashboard-patient-list`, {params: payload})
    },
    async getMapPaginationData(payload){
        return repository.get(`${payload.path}`)
    },
    async deletePatient(payload){
        return repository.post(`/delete-patient`,payload)
    }
}