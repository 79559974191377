export default {
    SET_DATA(state, data) {
        state.data = data;
    },
    SET_SEARCH_NAME(state, search_name) {
        state.search_name = search_name;
    },
    SET_SELECTED_FIRST_NAME(state, firstName) {
        state.selected_first_name = firstName;
    },
    SET_SELECTED_LAST_NAME(state, lastName) {
        state.selected_last_name = lastName;
    },
    SET_REPORT_NO(state, report_no) {
        state.report_no = report_no;
    }
};
