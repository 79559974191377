export default {
    getSelectedName(state) {
        return `${state.selected_first_name} ${state.selected_last_name}`
    },
    getFirstName(state) {
        return state.selected_first_name
    },
    getLastName(state) {
        return state.selected_last_name
    },
};
