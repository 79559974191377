import router from "@/router";
import repository from "@/repository/track-visit/index";
import {fail, loading, success} from "@/utils/alertUtils";

export default {
    fillHelpStatus({commit}, payload) {
        commit('SET_HELP_STATUS', payload)
    },
    fillEquipmentIdList({commit}, payload) {
        commit('SET_EQUIPMENT_ID_LIST', payload)
    },
    fillOtherDetails({commit}, payload) {
        commit('SET_OTHER_DETAILS', payload)
    },
    fillImages({commit}, payload) {
        commit('SET_IMAGES', payload)
    },
    fillPatientId({commit}, payload) {
        commit('SET_PATIENT_ID', payload)
    },
    async postRequest({rootState, state}) {
        console.log("postRequest state",state);
        let isLoading = true
        const name = JSON.parse(localStorage.getItem('reqData')).name
        const data = {
            patient_id: rootState.trackVisitMenu.patient_id,
            help_status: state.help_status,
            equipment_id_list: state.equipment_id_list,
            images: rootState.imageBase64,
            other_details: state.other_details,
            status: 1,
            created_by: name
        }
        if (isLoading) {
            loading().then(() => isLoading = false)
        }
        await repository.postRequest(data)
            .then(() => {
                success('บันทึกข้อมูลสำเร็จ')
                    .then(() => {
                        router.push({
                            name: 'track-visit-menu',
                            params: {patient_id: rootState.trackVisitMenu.patient_id}
                        })
                    })
            })
            .catch(() => {
                fail("ไม่สามารถบันทึกข้อมูลได้")
            })
    }
}
