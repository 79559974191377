export default {
    SET_NO1(state, payload) {
        state.no1 = payload;
    },
    SET_NO2(state, payload) {
        state.no2 = payload;
    },
    SET_NO3(state, payload) {
        state.no3 = payload;
    },
    SET_NO4(state, payload) {
        state.no4 = payload;
    },
    SET_NO5(state, payload) {
        state.no5 = payload;
    },
    SET_NO6(state, payload) {
        state.no6 = payload;
    },
    SET_NO7(state, payload) {
        state.no7 = payload;
    },
    SET_NO8(state, payload) {
        state.no8 = payload;
    },
    SET_NO9(state, payload) {
        state.no9 = payload;
    },
    SET_NO10(state, payload) {
        state.no10 = payload;
    }
};
