<template>
  <div id="app">
    <header-component v-if="title" :title="title" :subtitle="subtitle"/>
    <transition name="fade">
      <router-view/>
    </transition>
  </div>
</template>

<style lang="scss">
.fade-enter-active {
  animation: fadeIn 100ms ease-in;
}

.fade-leave-active {
  animation: fadeOut 100ms ease-in;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>
<script>
import HeaderComponent from "@/components/Header.vue";

export default {
  computed: {
    title() {
      return this.$route.meta['title'] || '';
    },
    subtitle() {
      return this.$route.meta['subtitle'] || '';
    }
  },
  components: {HeaderComponent},
  name: 'App'
}
</script>