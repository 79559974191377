import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import sweetalert2 from "sweetalert2";
import router from "@/router";
import axios from "@/repository";

export async function saveConfirmation() {
    return await Swal.fire({
            title: 'ยืนยันการบันทึกข้อมูล',
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#5d61ea',
            cancelButtonColor: '#d9d9d9',
            confirmButtonText: 'ยืนยัน',
            cancelButtonText: 'ยกเลิก',
        }
    );
}

export async function unableToDoTheRequest() {
    return await Swal.fire({
        subtitle: 'ระบบผิดพลาด',
        text: 'ไม่สามารถดำเนินการตามที่ร้องขอได้, โปรดลองอีกครั้งภายหลัง',
        icon: 'error',
        confirmButtonText: 'รับทราบ'
    });
}

export function failToLogin(title,text){
    return Swal.fire({
        title : title ?? 'เข้าสู่ระบบไม่สำเร็จ',
        text : text ?? 'กรุณาตรวจสอบข้อมูลให้ถูกต้อง',
        icon : 'error',
        confirmButtonText: 'ลองใหม่อีกครั้ง',
    }).then((e) =>{
        if(e.isConfirmed){
            window.location.reload();
        }
    })
}

export function loginSuccess(){
    return Swal.fire({
        title : 'เข้าสู่ระบบสำเร็จ',
        icon : 'success',
        timer : 1000
    }).then(() =>{
        axios.defaults.headers.common['X-User-Id'] = window.localStorage.getItem('X-userId')
        axios.defaults.headers.common['X-Auth-Token'] = window.localStorage.getItem('X-Auth-Token')
        router.go(-1);
    })
}

export function loading(){
    return Swal.fire({
        title: 'กำลังส่งคำร้องขอ',
        icon: 'info',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: false,
        onBeforeOpen: () => {
            sweetalert2.showLoading()
        }
    })
}

export function success(text){
    return Swal.fire({
        title: text,
        icon: 'success',
        showConfirmButton: false,
        timer: 1500
    })
}

export function fail(text){
    return Swal.fire({
        title: text,
        icon: 'error',
        confirmButtonText: 'ตกลง',
    })
}

export function deleteSucOrFail(status) {
    return Swal.fire({
        title: status ? 'ลบข้อมูลสำเร็จ' : 'ลบข้อมูลไม่สำเร็จ',
        icon: status ? 'success' : 'error',
        showConfirmButton: false,
        timer: 1500
    })
}

export function warning(text){
    return Swal.fire({
        title: text,
        icon: 'warning',
        confirmButtonText: 'ตกลง',
        confirmButtonColor: '#3085d6',
    })
}

export async function trackVisitPostData(repository, data, router, rootState) {
    return await Swal.fire({
        title: 'กดยืนยันเพื่อบันทึกข้อมูล',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก',
        reverseButtons: true
    }).then((result) => {
        if (result.isConfirmed) {
            repository(data)
                .then(() => {
                    sweetalert2.fire({
                        title: 'บันทึกข้อมูลสำเร็จ',
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 1500
                    }).then(() =>{
                        router.push({name : "track-visit-menu" , params : {patient_id : rootState.trackVisitMenu.patient_id}})
                    })
                })
                .catch(() => {
                    sweetalert2.fire({
                        title: 'บันทึกข้อมูลไม่สำเร็จ',
                        icon: 'error',
                        confirmButtonText: 'ตกลง',
                    })
                })
        } else if (result.dismiss === sweetalert2.DismissReason.cancel) {
            sweetalert2.fire({
                title: 'ยกเลิกการบันทึกข้อมูล',
                icon: 'error',
                confirmButtonText: 'ตกลง',
            })
        }
    })
}

export async function unableToValidateCitizenID() {
    return await Swal.fire({
        title: 'ระบบผิดพลาด',
        text: 'ไม่สามารถตรวจสอบเลขบัตรประชาชนได้, โปรดลองอีกครั้งภายหลัง',
        icon: 'error',
        confirmButtonText: 'รับทราบ'
    });
}

export async function duplicatedCitizenID() {
    return await Swal.fire({
        title: 'ระบบผิดพลาด',
        text: 'เลขบัตรประชาชนนี้มีอยู่ในระบบแล้ว',
        icon: 'error',
        confirmButtonText: 'รับทราบ'
    });
}

export async function invalidCitizenID() {
    return await Swal.fire({
        title: 'ระบบผิดพลาด',
        text: 'เลขบัตรประชาชนไม่ถูกต้อง',
        icon: 'error',
        confirmButtonText: 'รับทราบ'
    });
}

export function requestLocationLoading(){
    return Swal.fire({
        title: 'กำลังระบุพิกัด',
        icon: 'info',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: false,
    })
}