import Repository from "@/repository";


export default {
    staffSearchPatient(payload){
        if(payload.search_name && payload.report_no){
            return Repository.get(`search-patient-by-report-list/${payload.adminArea}/${payload.report_no}/${payload.search_name}`)
        }else if(payload.report_no){
            return Repository.get(`get-patient-by-report-list/${payload.adminArea}/${payload.report_no}`)
        }else if(payload.search_name){
            return Repository.get(`search-visiting-officer-report-list/${payload.adminArea}/${payload.search_name}`)
        }else{
            return Repository.get(`get-visiting-officer-report-list/${payload.adminArea}`)
        }
    },
    staffSearchPatientDetail(payload){
        // new path from P'Aom request
        if(payload.patient_id === undefined && payload.report_no === '2'){
            //api route ของรายงานบริบาร (เดิม)
            //return Repository.get(`get-visiting-officer-detail-report-list/${payload.adminArea}/${payload.selected_fname}/${payload.selected_lname}`)
            //api route ของรายงานบริบาร (ใหม่)
            //console.log("payload.report_no",payload.report_no);
            return Repository.get(`get-visiting-officer-by-month-detail-report-list/${payload.adminArea}/${payload.selected_fname}/${payload.selected_lname}`)
        }
        //console.log("payload.report_no",payload.report_no);
        return Repository.get(`/get-patient-detail-by-report-list/${payload.adminArea}/${payload.patient_id}/${payload.report_no}`)
    },
    staffMapPaginationData(payload){
        return Repository.get(`${payload.path}`)
    }
}