import Repository from "@/repository";

export default {
    getPersonalInfo(patient_id) {
        return Repository.get(`get-patient-info/${patient_id}`)
    },
    editPatient(patient_id, payload) {
        return Repository.post(`patient-edit/${patient_id}`, payload)
    },
    getCaregiverInfo(patient_id) {
        return Repository.get(`get-caregiver-info/${patient_id}`)
    },
    editCaregiverInfo(patient_id, payload) {
        return Repository.post(`post-caregiver-edit/${patient_id}`, payload);
    },
    getHealthInfo(patient_id) {
        return Repository.get(`get-health-info/${patient_id}`);
    },
    editLTCStatus(patient_id, payload) {
        return Repository.post(`post-health-status/${patient_id}`, payload);
    },
    getReportCount(payload){
        return Repository.get(`get-count-patient-detail-by-report-list/${payload.adminArea}/${payload.patientId}/${payload.reportNo}`)
    },
    postVisitRecord(payload){
        return Repository.post(`visit-register`, payload)
    },
    postPressureUlcerHealingAssessment(payload){
        return Repository.post(`post-pressure-ulcer-healing-assessment`, payload)
    },
    postPressureUlcerRiskAssessment(payload){
        return Repository.post(`post-pressure-ulcer-risk-assessment`, payload)
    },
    postExamResultRegister(payload){
        return Repository.post(`exam-result-register`, payload)
    },
    getPatientData(payload){
        return Repository.get(`patient-qr-register/${payload.patient_id}`)
    },
    postRequest(payload) {
        return Repository.post('post-help-request',payload)
    }
}