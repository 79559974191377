import repository from "@/repository/staff";

export default {
    async mapPaginationData({commit, state}, pageNumber) {
        const path = `${state.data.path}?page=${pageNumber}`;
        const {data} = await repository.staffMapPaginationData({path});
        commit("SET_DATA", data);
    },
    async fetchData({commit}, payload) {
        const adminArea = localStorage.getItem("adminArea");
        const {data} = await repository.staffSearchPatient({adminArea, report_no: payload.report_no});
        commit("SET_DATA", data);
    },
};
