export default {
    SET_HELP_STATUS(state, payload) {
        state.help_status = payload
    },
    SET_EQUIPMENT_ID_LIST(state, payload) {
        state.equipment_id_list = payload
    },
    SET_OTHER_DETAILS(state, payload) {
        state.other_details = payload
    },
    SET_IMAGES(state, payload) {
        state.images = payload
    },
};
