import repository from "@/repository/staff";
import router from "@/router";

export default {
    async gotoUserDetails({commit},payload){
        const adminArea = localStorage.getItem("adminArea");
        commit("SET_SELECTED_FIRST_NAME", payload.selected_fname);
        commit("SET_SELECTED_LAST_NAME", payload.selected_lname);
        //prepare data before go to details page
        const {data} = await repository.staffSearchPatientDetail({adminArea, report_no: payload.report_no, patient_id: payload.patient_id, selected_fname: payload.selected_fname, selected_lname: payload.selected_lname});
        //เพิ่ม รายงานที่ เข้าไปในผลข้อมูล
        data.report_no = payload.report_no;
        //console.log("gotoUserDetails data",data);

        commit("staffDetailsPatientStore/SET_DATA", data, {root: true});
        await router.push({name: `${payload.detailPath}`})
    },
    async mapPaginationData({commit,state}, pageNumber) {
        const path = `${state.data.path}?page=${pageNumber}`;
        const {data} = await repository.staffMapPaginationData({path});
        commit("SET_DATA", data);
    },
    async fetchData({commit,state}, payload){
        const adminArea = localStorage.getItem("adminArea");
        commit("SET_SEARCH_NAME", payload?.search_name ?? "");
        if(payload?.report_no){
            commit("SET_REPORT_NO", payload.report_no)
        }
        // this case handle when user enters f0039
        if(payload?.report_no === "2" || payload === undefined){
            const {data} = await repository.staffSearchPatient({adminArea,search_name: payload?.search_name});
            commit("SET_DATA", data);
            return;
        }
        // this case handle when user enters another site in staff page
        if(state.search_name === ""){
            const {data} = await repository.staffSearchPatient({adminArea, report_no: state.report_no});
            commit("SET_DATA", data);
        }else{
            const {data} = await repository.staffSearchPatient({adminArea, report_no: state.report_no, search_name: state.search_name});
            commit("SET_DATA", data);
        }
    }
};
