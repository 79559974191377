const register = {
    name: 'registration',
    path: "/register",
    redirect: "/register/consent", // this path is not exist, just only for to be parent path so redirect to child path
    component: {
        render(h) {
            return h("router-view"); // <-- this is the trick! use to render router-view without use template
        },
    },
    children: [
        {
            path: "consent",
            component: () => import("@/views/register/ConsentView.vue"),
        },
        {
            path: "area-cg",
            component: () => import("@/views/register/AreaView.vue"),
        },
        {
            path: "area-cg-confirm",
            component: () => import("@/views/register/AreaConfirmView.vue"),
        },
        {
            path: "user-form",
            component: () => import("@/views/register/UserFormView.vue"),
        }
    ],
};

export default register;
