import store from "@/store";

const staff = {
    name: "staff",
    path: "/staff",
    redirect: "/staff/staff-menu", // this path is not exist, just only for to be parent path so redirect to child path
    component: {
        render(h) {
            return h("router-view"); // <-- this is the trick! use to render router-view without use template
        },
    },
    children: [
        {
            path: "staff-menu",
            component: () => import("@/views/staff/StaffMenuView.vue"),
        },
        {
            path: "search-patient",
            component: () => import("@/views/staff/elderly-and-dependent-people/search-patient.vue"),
            beforeEnter : async (to, from, next) => {
                await store.dispatch("staffListPatient/fetchData",{report_no: 1})
                    .then(() => {
                        next();
                    });
            }
        },
        {
            path: "search-patient-detail",
            name: "search-patient-detail",
            component: () => import("@/views/staff/elderly-and-dependent-people/search-patient-detail.vue"),
        },
        {
            path: "search-volunteer",
            component: () => import("@/views/staff/elderly-and-dependent-people/search-volunteer.vue"),
            beforeEnter : async (to, from, next) => {
                await store.dispatch("staffListPatient/fetchData")
                    .then(() => {
                        next();
                    });
            }
        },
        {
            path: "search-volunteer-detail",
            name: "search-volunteer-detail",
            component: () => import("@/views/staff/elderly-and-dependent-people/search-volunteer-detail.vue"),
        },
        {
            path: "search-assess",
            component: () => import("@/views/staff/elderly-and-dependent-people/search-assess.vue"),
            beforeEnter : async (to, from, next) => {
                await store.dispatch("staffListPatient/fetchData",{report_no: 3})
                    .then(() => {
                        next();
                    });
            }
        },
        {
            path: "search-assess-detail",
            name: "search-assess-detail",
            component: () => import("@/views/staff/elderly-and-dependent-people/search-assess-detail.vue"),
        },
        // reomi
        {
            path: "search-pressure-sores",
            component: () => import("@/views/staff/elderly-and-dependent-people/search-pressure-sores.vue"),
            beforeEnter : async (to, from, next) => {
                await store.dispatch("staffListPatient/fetchData",{report_no: 4})
                    .then(() => {
                        next();
                    });
            }
        },
        {
            path: "search-pressure-sores-detail",
            name: "search-pressure-sores-detail",
            component: () => import("@/views/staff/elderly-and-dependent-people/search-pressure-sores-detail.vue"),
        },
        {
            path: "search-pressure-sores-healed",
            component: () => import("@/views/staff/elderly-and-dependent-people/search-pressure-sores-healed.vue"),
            beforeEnter : async (to, from, next) => {
                await store.dispatch("staffListPatient/fetchData",{report_no: 5})
                    .then(() => {
                        next();
                    });
            }
        },
        {
            path: "search-pressure-sores-healed-detail",
            name: "search-pressure-sores-healed-detail",
            component: () => import("@/views/staff/elderly-and-dependent-people/search-pressure-sores-healed-detail.vue"),
        },
    ]
};

export default staff;
