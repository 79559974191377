<template>
  <div class="header-bar">
    <div class="header-content">
      <p class="title">{{ title }} {{ subtitle }}</p>
    </div>
    <div class="header-margin"></div>
  </div>
</template>

<script>
export default {
  name: "HeaderComponent",
  props: {
    title: {
      type: String,
      default: "XXX",
    },
    subtitle: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
$primary-color: #5d61ea;
$secondary-color: #d9d9d9;
$white-color: #fff;
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
p.title {
  font-size: 1rem;
  color: white;
}

p {
  color: white;
  font-size: 0.625rem;
}

div.header-content {
  z-index: 999;
  padding-top: 15px;
  padding-bottom: 15px;
  position: fixed;
  background-color: $primary-color;
  min-height: fit-content;
  width: 100vw;
  position: fixed;
  top: 0;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.4);
}
h2 {
  font-size: 1.063rem;
}

p {
  font-size: 0.625rem;
}
.header-margin {
margin-bottom: 77px;
}


div.header-content {
  text-align: center;
}
</style>
