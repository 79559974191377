export default {
  SET_AUTHORITY_ID(state, payload) {
    state.authorityId = payload;
  },
  SET_AREA_CODE(state, payload) {
    state.areaCode = payload;
  },
  SET_DATA_AREA(state, payload) {
    state.dataArea = payload;
  },
  SET_FIRST_NAME(state, payload) {
    state.firstName = payload;
  },
  SET_LAST_NAME(state, payload) {
    state.lastName = payload;
  },
  SET_TEL(state, payload) {
    state.tel = payload;
  },
  RESET_DATA_AREA(state) {
    state.dataArea = null;
  },
  RESET_AREA_CODE(state) {
    state.areaCode = "";
  },
  SET_USERNAME(state, payload) {
    state.username = payload;
  },
  SET_PASSWORD(state, payload) {
    state.password = payload;
  },
};
