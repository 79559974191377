export default {
    getFullName(state){
        //console.log('getFullName state',state);
        if(state.patientData){
            return state.patientData.data[0].firstname + ' ' + state.patientData.data[0].lastname;
        }else{
            return "";
        }
    },
    getFullAddress(state){
        if(state.patientData){
            return `ที่อยู่ บ้านเลขที่ ${state.patientData.data[0].house_number} หมู่ที่/ชุมชน ${state.patientData.data[0].moo} ต.${state.patientData.data[0].sub_district} อ.${state.patientData.data[0].district} จ.${state.patientData.data[0].province}, ${state.patientData.data[0].postal_code}`
        }else{
            return "";
        }
    },
    getPatientId(state){
        return state.patient_id
    },
    getPatientLocation(state){
        return {
            latitude: state.patientData.data[0].latitude ?? 13.736717,
            longitude: state.patientData.data[0].longitude ?? 100.523186
        }
    },
    getReportData(state){
        return state.reportData === undefined ? {"date" : "ไม่มีข้อมูล", "no" : "ไม่มีข้อมูล"} : state.reportData
    }
};
