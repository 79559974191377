import store from "@/store";

const search = {
    name: "search",
    path: "/search",
    component: () => import("@/views/search/SearchView.vue"),
    beforeEnter: async (to, from, next) => {
        // TODO : need to change payload to user area, but wait api to get user area
        await store.dispatch("search/fetchSearchResults")
            .then(() => {
                next();
            });
    }
};

export default search;
