export default {
    // table zone
    table_data : "",

//   searching part
    name:"",
    age_min: "",
    age_max: "",
    disabilities:"",
    blood_glucose_min:"",
    blood_glucose_max:"",
    blood_pressure:"",
    ltc_status:"",
    ADL:"",

    // map zone
    ADL_TXT:"",
    LTC_TXT:"",
};
