export default {
    name: "patient",
    path: "/patient",
    redirect: "/patient/register/1",
    component: {
        render(h) {
            return h("router-view"); // <-- this is the trick! use to render router-view without use template
        },
    },
    children: [
        {
            name: 'patient-register',
            path: 'register/:step',
            component: () => import('@/views/FormPatient.vue'),
            beforeEnter(to, from, next) {
                to.meta.title = 'ลงทะเบียน';
                to.meta.subtitle = [
                    '(หน้า 1/6)', '(หน้า 2/6)', '(หน้า 3/6)',
                    '(หน้า 4/6)', '(หน้า 5/6)'
                ][to.params.step - 1];
                next();
            },
            
        },
        {
            name: 'patient-card',
            path: 'card/:patient_id',
            component: () => import('@/views/PatientCard.vue')
        }
    ]
};