export default {
    no1: 0,
    no2: 0,
    no3: 0,
    no4: 0,
    no5: 0,
    no6: 0,
    no7: 0,
    no8: 0,
    no9: 0,
    no10: 0,
};
