import router from "@/router";
import repository from "@/repository/track-visit/index";
import {trackVisitPostData} from '@/utils/alertUtils'

export default {
    fillScore1({commit}, score) {
        commit('SET_ASSESSMENT_SCORE_1', score)
    }, fillScore2({commit}, score) {
        commit('SET_ASSESSMENT_SCORE_2', score)
    }, fillScore3({commit}, score) {
        commit('SET_ASSESSMENT_SCORE_3', score)
    }, async postScore({getters, rootState, state}) {
        const created_by = JSON.parse(localStorage.getItem('reqData')).name
        const data = {
            "patient_id": rootState.trackVisitMenu.patient_id,
            "assessment_date": getters.getCurrentDate,
            "assessment_score_1": state.assessment_score_1,
            "assessment_score_2": state.assessment_score_2,
            "assessment_score_3": state.assessment_score_3,
            "created_by": created_by,
        }
        await trackVisitPostData(repository.postPressureUlcerHealingAssessment, data, router, rootState)
    }
}
