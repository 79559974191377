export default {
    SET_PATIENT_DATA(state,payload){
        state.patientData = payload
    },
    SET_PATIENT_ID(state, payload) {
        state.patient_id = payload
    },
    SET_REPORT_DATA(state, payload) {
        state.reportData = payload
    },
};
