export default {
    SET_TABLE_DATA(state, data) {
        state.table_data = data;
    },
    SET_NAME(state, data) {
        state.name = data;
    },
    SET_AGE_MIN(state, data) {
        state.age_min = data;
    },
    SET_AGE_MAX(state, data) {
        state.age_max = data;
    },
    SET_DISABILITIES(state, data) {
        state.disabilities = data;
    },
    SET_BLOOD_GLUCOSE_MIN(state, data) {
        state.blood_glucose_min = data;
    },
    SET_BLOOD_GLUCOSE_MAX(state, data) {
        state.blood_glucose_max = data;
    },
    SET_BLOOD_PRESSURE(state, data) {
        state.blood_pressure = data;
    },
    SET_LTC_STATUS(state, data) {
        state.ltc_status = data;
    },
    SET_ADL(state, data) {
        state.ADL = data;
    },
};
