import Vue from "vue";
import VueRouter from "vue-router";
import register from "./routes/register";
import registerPatient from "@/router/routes/patient";
import search from "@/router/routes/search";
import staff from "@/router/routes/staff";
import trackVisit from "@/router/routes/track-visit";
import admin from "@/router/routes/admin";
import login from "@/router/routes/login";

Vue.use(VueRouter);

const routes = [
    register,
    login,
    registerPatient,
    search,
    staff,
    trackVisit,
    admin,
    {
        name: "root",
        path: "/",
        component: () => import("@/views/Home.vue")
    },
    {
        name: "not-found",
        path: "*",
        component: () => import("@/views/errors/Errors.vue"),
        meta: {
            message: "ไม่พบหน้าที่คุณต้องการ",
            advice: "กรุณาตรวจสอบ URL อีกครั้ง"
        }
    },
    {
        name: "403",
        path: "/403",
        component: () => import("@/views/errors/Errors.vue")
    },
    {
        name: "409",
        path: "/409/:message/:advice",
        component: () => import("@/views/errors/Errors.vue"),
        meta: {
            message: "ตรวจพบการเข้าสู่ระบบซ้ำ",
            advice: "ตรวจพบการใช้บัญชีไลน์นี้กับหลายบัญชีผู้ใช้งาน กรุณาออกจากระบบและเข้าสู่ระบบใหม่อีกครั้ง",
            btn_msg: "ออกจากระบบแล้ว กลับสู่หน้าหลัก",
            reset: true
        }
    }
]

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

export default router;
