export default {
    getPatientInfo(state) {
        const data = state.patientData[0];
        const dateData = data.birth_date.split('/');
        return {
            prefix: data.title,
            firstName: data.firstname,
            lastName: data.lastname,
            id_card : data.id_card,
            date : dateData[0],
            month : dateData[1],
            year : dateData[2],
            age : data.age,
            phone_number : data.phone_number,
            latitude : data.latitude,
            longitude : data.longitude,
            images_path : data.images_path,
            house_number : data.house_number,
            moo : data.moo,
            other : data.other,
            sub_district : data.sub_district,
            district : data.district,
            province : data.province,
            postal_code : data.postal_code
        };
    },
    getPatientImage(state){
        return state.patientData[0].images_path === null ? null : state.patientData[0].images_path.split(',')[0];
    }
};
