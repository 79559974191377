import repository from "@/repository/authentication";
import {failToLogin, loading, loginSuccess} from "@/utils/alertUtils";
import {liff} from "@line/liff";

export default async function (userId, username, password) {
    loading()
    const body = {
        "username": username,
        "password": password,
        "userid": userId,
    }
    await repository.login(body)
        .then((resp) => {
            const data = resp.data;
            if (data.action === "fail") throw new Error("Role not satisfied");
            localStorage.setItem("reqData", JSON.stringify(data.response));
            localStorage.setItem("userId", JSON.stringify(data.response.id));
            localStorage.setItem("X-Auth-Token", data.response.token);
            findAdminArea(resp.data.response);
            loginSuccess();
            liff.closeWindow();
        })
        .catch((e) => {
            if (e.message === "Role not satisfied") {
                failToLogin("ท่านลงทะเบียนไม่สำเร็จ", "โปรดติดต่อเจ้าหน้าที่เพื่อขอรับรหัสพื้นที่ในการใช้งาน")
            } else {
                failToLogin()
            }
        }).finally(() => {
        })
}

function findAdminArea(data) {
    const authorityName = data.authority.name;
    for (let area of data.administrationAreas) {
        if (area.name === authorityName) {
            localStorage.setItem("adminArea", JSON.stringify(area.id));
            return;
        }
    }
    //not found
    localStorage.setItem("adminArea", JSON.stringify(data.administrationAreas[0].id));
}