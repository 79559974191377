import axios from "axios";
import router from "@/router";

const baseURL = `${process.env.VUE_APP_BASE_URL}/api`;
const instance = axios.create({
    baseURL
})
instance.defaults.headers.common['X-User-Id'] = window.localStorage.getItem('X-userId')
instance.defaults.headers.common['X-Auth-Token'] = window.localStorage.getItem('X-Auth-Token')
instance.interceptors.response.use(
    response => response,
    async error => {
        if (error.response.status === 401) {
            await router.push({name: 'login'})
            return Promise.resolve(error.response)
        } else if (error.response.status === 403) {
            await router.replace({name: '403'})
            return Promise.resolve(error.response)
        } else if (error.response.status === 409) {
            await router.replace({name: '409'})
            return Promise.resolve(error.response)
        }
        return Promise.reject(error)
    }
)

export default instance