import repository from "@/repository/search/index";

export default {
    fillSearchName({commit}, search_name) {
        commit("SET_SEARCH_NAME", search_name);
    },
    async fetchSearchResults({commit, state}) {
        const query = localStorage.getItem("adminArea");
        if (state.search_name !== "") {
            const path = `/search-patient-list/${query}/${state.search_name}`
            const {data} = await repository.fetchSearchResults({path});
            commit("SET_DATA", data);
        } else {
            const path = `/get-patient-list/${query}`
            const {data} = await repository.fetchSearchResults({path});
            commit("SET_DATA", data);
        }
    },
    async mapPaginationData({commit, state}, query) {
        const path = `${state.data.path}?page=${query}`;
        const {data} = await repository.fetchSearchResults({path});
        commit("SET_DATA", data);
    },
};
