import store from "@/store";
import repository from "@/repository/track-visit/index";

async function updatePatientData(to, from, next) {
    const patient_id = to.params.patient_id
    store.commit('trackVisitMenu/SET_PATIENT_ID', patient_id)
    await store.dispatch('trackVisitMenu/getPatientData', patient_id)
    next();
}
async function getReportCount(to, from, next,reportNo) {
    const {data} = await repository.getReportCount({
        adminArea: localStorage.getItem('adminArea'),
        patientId: to.params.patient_id,
        reportNo: reportNo
    })
    store.commit('trackVisitMenu/SET_REPORT_DATA', data[0])
    next()
}
export default {
    name: "track",
    path: "/track-visit",
    redirect: "/track-visit/menu", // this path is not exist, just only for to be parent path so redirect to child path
    component: {
        render(h) {
            return h("router-view"); // <-- this is the trick! use to render router-view without use template
        },
    },
    children: [
        {
            name: "track-visit-menu",
            path: "menu/:patient_id",
            component: () => import("@/views/track-visit/MainMenuView.vue"),
            beforeEnter: updatePatientData,
            meta: {title: 'เมนูติดตามตรวจเยี่ยม', subtitle: 'track & visit'}
        },
        {
            name: "edit-personal-info-menu",
            path: "edit-personal-info-menu/:patient_id",
            component: () => import("@/views/track-visit/EditPersonalInfoMenuView.vue"),
            beforeEnter: updatePatientData,
            meta: {title: 'เมนูติดตามตรวจเยี่ยม', subtitle: 'track & visit'}
        },
        {
            name: "visit-menu",
            path: "visit-menu/:patient_id",
            component: () => import("@/views/track-visit/VisitMenuView.vue"),
            meta: {title: 'เมนูติดตามตรวจเยี่ยม', subtitle: 'track & visit'}
        },
        {
            name: "edit-personal-info",
            path: "edit-personal-info/:patient_id",
            component: () => import("@/views/track-visit/edit/EditPersonalInfoView.vue"),
            meta: {title: 'ประวัติส่วนตัว', subtitle: 'ข้อมูลระบุตัวตน'}
        },
        {
            name: "edit-caregiver-info",
            path: "edit-caregiver-info/:patient_id",
            component: () => import("@/views/track-visit/edit/EditCaregiverView.vue"),
            meta: {title: 'เมนูติดตามตรวจเยี่ยม', subtitle: 'track & visit'}
        },
        {
            name: "edit-ltc-status",
            path: "edit-ltc-status/:patient_id",
            component: () => import("@/views/track-visit/edit/EditLTCStatusView.vue"),
            meta: {title: 'เมนูติดตามตรวจเยี่ยม', subtitle: 'track & visit'}
        },
        {
            name: "insert-health-record",
            path: "insert-health-record/:patient_id",
            component: () => import("@/views/track-visit/edit/HealthRecordFormView.vue"),
            meta: {title: 'เมนูติดตามตรวจเยี่ยม', subtitle: 'track & visit'}
        },
        {
            name: "insert-visit-record",
            path: "insert-visit-record/:patient_id",
            component: () => import("@/views/track-visit/edit/VisitRecordFormView.vue"),
            meta: {title: 'เมนูติดตามตรวจเยี่ยม', subtitle: 'track & visit'}
        },
        {
            name: "set-location",
            path: "set-location/:patient_id",
            component: () => import("@/views/track-visit/SetLocationView.vue"),
            beforeEnter: updatePatientData,
            meta: {title: 'เมนูติดตามตรวจเยี่ยม', subtitle: 'track & visit'}
        },
        {
            path: "evaluate-activity/:patient_id",
            component: () => import("@/views/track-visit/EvaluateActivity.vue"),
            meta: {title: 'เมนูติดตามตรวจเยี่ยม', subtitle: 'track & visit'},
            props: true,
            beforeEnter: (to, from, next) => getReportCount(to, from, next,3)
        },
        {
            path: "evaluate-pressure-sores/:patient_id",
            component: () => import("@/views/track-visit/EvaluatePressureSores.vue"),
            beforeEnter: (to, from, next) => getReportCount(to, from, next,4),
            meta: {title: 'เมนูติดตามตรวจเยี่ยม', subtitle: 'track & visit'}
        },
        {
            path: "report/:patient_id",
            component: () => import("@/views/track-visit/ReportView.vue"),
            beforeEnter: updatePatientData,
            meta: {title: 'เมนูติดตามตรวจเยี่ยม', subtitle: 'track & visit'}
        },
        {
            path: "track-pressure-sores/:patient_id",
            component: () => import("@/views/track-visit/TrackPressureSores.vue"),
            beforeEnter: (to, from, next) => getReportCount(to, from, next,5),
            meta: {title: 'เมนูติดตามตรวจเยี่ยม', subtitle: 'track & visit'}
        },
    ]
}
